import React, { ComponentProps, MouseEventHandler, useCallback } from "react"
import { ButtonA } from "./Button/Button"

interface SmoothScrollButtonProps extends ComponentProps<typeof ButtonA> {
    href: string
}
export const SmoothScrollButton: React.FC<SmoothScrollButtonProps> = ({ children, href, ...props }) => {
    const doScroll = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
        event.preventDefault()

        const elem = document.querySelector(href)

        if (!elem) {
            return
        }

        elem.scrollIntoView({
            behavior: "smooth"
        })
    }, [href])

    return <ButtonA {...props} onClick={doScroll} href={href}>
        {children}
    </ButtonA>
}
