import React, { useMemo } from "react"

import Img from "gatsby-image"

import Layout from "../components/Layout"
import { PageType } from "../index"
import Seo from "../components/Seo"
import { SmoothScrollButton } from "../components/SmoothScrollButton"
import Carousel from "../components/Carousel/Carousel"
import { graphql, useStaticQuery } from "gatsby"
import { ButtonLink } from "../components/Button/Button"
import { CarouselItem } from "../components/Carousel/CarouselItem"
import CustomerSlider from "../components/CustomerSlider"
import ImageAndText from "../components/ImageAndText/ImageAndText"
import PreHeading from "../components/PreHeading"

import Awards from "../components/Awards/Awards"

import LangAwareLink from "../components/LangAwareLink"
import { SoloToOchestraEn } from "../components/SoloToOrchestra/SoloToOrchestra.de"

import Style from "./index.module.css"

const IndexEn: PageType = ({ location }) => {
    const {
        aboutUsImage,
        aboutUsImageTablet,

        carouselCulture,
        carouselTraining,
        carouselSales,

        carouselCultureMobile,
        carouselTrainingMobile,
        carouselSalesMobile,

        productsCulture,
        productsTraining,
        productsSales,
    } = useStaticQuery(graphql`        
        query IndexEn {
            aboutUsImage: file(relativePath: { eq: "office-meeting.jpg" }) {
                ...ImageAndTextSideImage
            }
            aboutUsImageTablet: file(relativePath: { eq: "office-meeting.jpg" }) {
                ...ImageAndTextSideImage_Tablet
            }

            carouselCulture: file(relativePath: { eq: "culture.jpg" }) {
                ...CarouselItemImage
            }
            carouselTraining: file(relativePath: { eq: "training.jpg" }) {
                ...CarouselItemImage
            }
            carouselSales: file(relativePath: { eq: "sales.jpg" }) {
                ...CarouselItemImage
            }
            
            carouselCultureMobile: file(relativePath: { eq: "culture.jpg" }) {
                ...CarouselItemImageMobile
            }
            carouselTrainingMobile: file(relativePath: { eq: "training.jpg" }) {
                ...CarouselItemImageMobile
            }
            carouselSalesMobile: file(relativePath: { eq: "sales.jpg" }) {
                ...CarouselItemImageMobile
            }

            productsCulture: file(relativePath: { eq: "culture.jpg" }) {
                ...ProductImage
            }
            productsTraining: file(relativePath: { eq: "training.jpg" }) {
                ...ProductImage
            }
            productsSales: file(relativePath: { eq: "sales.jpg" }) {
                ...ProductImage
            }
        }
    `)

     const carouselItems = useMemo(() => {
        if (
            !carouselCulture ||
            !carouselCultureMobile ||
            !carouselTraining ||
            !carouselTrainingMobile ||
            !carouselSales ||
            !carouselSalesMobile
        ) {
            return []
        }

        return [
            CarouselItem(
                "Quadio Culture",
                "Embrace Corporate Culture",
                carouselCulture,
                carouselCultureMobile,
                (<>
                    <p className="text-white">Get onboard, get set, kickoff. Various personalities and one vision. Excellent leadership and strong corporate culture coupled with Quadio is the best way to yield success.</p>
                    <ButtonLink appearance="primary" to="/products/culture">Explore</ButtonLink>
                </>)
            ),
            CarouselItem(
                "Quadio Training",
                "Training and Knowledge Acquisition",
                carouselTraining,
                carouselTrainingMobile,
                (<>
                    <p className="text-white">Maximize the ‘Return on Learning Invest’ with effective knowledge management and reinforced knowledge acquisition regardless of time and place.</p>
                    <ButtonLink appearance="primary" to="/products/training">Explore</ButtonLink>
                </>)
            ),
            CarouselItem(
                "Quadio Sales",
                "Sales and Marketing",
                carouselSales,
                carouselSalesMobile,
                (<>
                    <p className="text-white">Sales representatives grown into brand ambassadors help define an optimal brand portfolio strategy with maximum reach and minimum explanatory need whilst customers converted into followers excel in understanding the products and thus create a unique user experience.</p>
                    <ButtonLink appearance="primary" to="/products/sales">Explore</ButtonLink>
                </>)
            ),
        ]
    }, [carouselCulture, carouselCultureMobile, carouselTraining, carouselTrainingMobile, carouselSales, carouselSalesMobile])

    return <Layout location={location}>
        <Seo title="Start" />
        <section className="section-x-margin">
            <div className="flex flex-wrap mobile:px-8 desktop:flex-no-wrap px-12 desktop:px-40">
                <div className="w-full desktop:w-3/5">
                    <h2 className="mobile:h3 sm_desktop:h2 desktop:h1">
                        Digital.<br />
                        Entrepreneurial.<br />
                        Empathic.<br />
                    </h2>
                </div>
                <div className="w-full desktop:w-2/5 mt-0">
                    <p className="mb-4">
                        QUADIO – your digital solution provider facilitating smart knowledge management, empathy-driven collaborative organizational culture, efficacious onboarding and optimal brand portfolio strategy.
                    </p>
                    <SmoothScrollButton
                        appearance="primary"
                        href="#products"
                    >
                        Explore
                    </SmoothScrollButton>
                </div>
            </div>
        </section>
        <div id="products" className="inline-block w-full -mt-12" />
        <section className="mt-12">
            <Carousel className="mb-12 px-12 mobile:px-4 pb-0">
                {carouselItems}
            </Carousel>

            <div className="flex flex-no-wrap mobile:flex-wrap mobile:text-center justify-center sm_desktop:px-12 sm_desktop:justify-between desktop:justify-around px-12 desktop:px-40 section-x-margin">
                <div className="mobile:w-full w-1/3 px-4 m-auto mb-8 mobile:mb-12 sm_desktop:px-0 sm_desktop:m-0 sm_desktop:w-auto desktop:mb-0">
                    <span className="block tablet:h3 desktop:h1">+ 40.000</span>
                    <span className="text-sm text-blue-ink uppercase">Users</span>
                </div>
                <div className="mobile:w-full w-1/3 px-4 m-auto mb-8 mobile:mb-12 sm_desktop:px-0 sm_desktop:m-0 sm_desktop:w-auto desktop:mb-0">
                    <span className="block tablet:h3 desktop:h1">+ 1,3 Mio.</span>
                    <span className="text-sm text-blue-ink uppercase">Persons reached</span>
                </div>
                <div className="mobile:w-full w-1/3 px-4 m-auto mb-8 mobile:mb-12 sm_desktop:px-0 sm_desktop:m-0 sm_desktop:w-auto desktop:mb-0">
                    <span className="block tablet:h3 desktop:h1">+ 18.000</span>
                    <span className="text-sm text-blue-ink uppercase">Locations</span>
                </div>
            </div>
        </section>
        <SoloToOchestraEn />
        <section className="section-x-margin">
            <div className="hidden" aria-hidden={false}>
                Valued customers
            </div>

            <CustomerSlider />
        </section>
        <section>
            <ImageAndText
                image={aboutUsImage}
                tabletImage={aboutUsImageTablet}
                fancy
            >
                <PreHeading>
                    Our venture
                </PreHeading>
                <h2>Empowering People and Human Capital</h2>
                <p>Our products are tailor-made solutions ensuring your organizational growth and success. Whilst success is undoubtedly the outcome of each and every employee’s valuable contribution, it is the human capital that we strive to strengthen and empower in a unique manner. We have travelled a long way to explore the exceptional essence of this formula: tangible change can only be possible when technology is clearly aimed at none other than people. Thus, our products are meticulously crafted keeping people as its core element for they are its end user.</p>
                <ButtonLink appearance="secondary" to="/about-us">Our venture</ButtonLink>
            </ImageAndText>
        </section>
        <section className="section-x-margin">
            <div className={Style.products_section_heading}>
                <PreHeading inline>
                    Products
                </PreHeading>
                <h2 className="mobile:h3">How can we improve your business?</h2>
            </div>
            <div className={Style.products_wrap}>
                <div className={Style.product}>
                    <div className={Style.product_image}>
                        <LangAwareLink aria-hidden className="block" to="/products/culture">
                            <Img className="w-full" fixed={productsCulture.childImageSharp.fixed} />
                        </LangAwareLink>
                    </div>
                    <div className={Style.product_content}>
                        <h3 className={Style.product_heading}>
                            Corporate Culture
                        </h3>
                        <p className={Style.product_content_text}>
                            Get onboard, get set, kickoff. Various personalities and one vision. Excellent leadership and strong corporate culture coupled with Quadio is the best way to yield success.
                        </p>
                        <div className={Style.product_actions}>
                            <ButtonLink appearance="primary" to="/products/culture">Quadio Culture</ButtonLink>
                        </div>
                    </div>
                </div>
                <div className={Style.product}>
                    <div className={Style.product_image}>
                        <LangAwareLink aria-hidden className="block" to="/products/training">
                            <Img className="w-full" fixed={productsTraining.childImageSharp.fixed} />
                        </LangAwareLink>
                    </div>
                    <div className={Style.product_content}>
                        <h3 className={Style.product_heading}>
                            Training and Knowledge Acquisition
                        </h3>
                        <p className={Style.product_content_text}>
                            Maximize the ‘Return on Learning Invest’ with effective knowledge management and reinforced knowledge acquisition regardless of time and place.
                        </p>
                        <div className={Style.product_actions}>
                            <ButtonLink appearance="primary" to="/products/training">Quadio Training</ButtonLink>
                        </div>
                    </div>
                </div>
                <div className={Style.product}>
                    <div className={Style.product_image}>
                        <LangAwareLink aria-hidden className="block" to="/products/sales">
                            <Img className="w-full" fixed={productsSales.childImageSharp.fixed} />
                        </LangAwareLink>
                    </div>
                    <div className={Style.product_content}>
                        <h3 className={Style.product_heading}>
                            Sales and Marketing
                        </h3>
                        <p className={Style.product_content_text}>
                            Sales representatives grown into brand ambassadors help define an optimal brand portfolio strategy with maximum reach and minimum explanatory need whilst customers converted into followers excel in understanding the products and thus create a unique user experience.
                        </p>
                        <div className={Style.product_actions}>
                            <ButtonLink appearance="primary" to="/products/sales">Quadio Sales</ButtonLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className={Style.products_section_awards}>
                <span hidden>Awards</span>
                <Awards />
            </div>
        </section>
    </Layout>
}

export default IndexEn
