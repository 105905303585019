import React, { useState } from "react"

import Style from "./Carousel.module.css"

export interface CarouselItemProps {
    active: boolean
    onActivate: () => void
}

interface CarouselProps {
    className?: string,
    children: React.FC<CarouselItemProps>[]
}
const Carousel: React.FC<CarouselProps> = ({ children, className = "" }) => {
    const [ active, setActive ] = useState(0)

    return <ul className={`${Style.slide_wrapper} ${className}`}>
        {children.map((Child, index) => {
            const className = [Style.slide]

            if (active === index) {
                className.push(Style.slide_active)
            }

            return <li className={className.join(" ")} key={`carousel-slide-${index}`}>
                <Child active={active === index} onActivate={() => { setActive(index) }} />
            </li>
        })}
    </ul>
}

export default Carousel
