import React, { ReactNode } from "react"

import { Button } from "../Button/Button"

import { CarouselItemProps } from "./Carousel"

import classNames from "../../helpers/classNames"
import { ImageFluid } from "../../helpers/Image"
import Img from "gatsby-image/withIEPolyfill"

import { graphql } from "gatsby"
import { media } from "../../../config/breakpoints"

import Style from "./CarouselItem.module.css"

export const CarouselItem = (
    name: string,
    title: string,
    image: ImageFluid,
    mobileImage: ImageFluid,
    description: ReactNode,
): React.FC<CarouselItemProps> => React.memo(({ active, onActivate }) => {
    const images = [
        image.childImageSharp.fluid,
        {
            ...mobileImage.childImageSharp.fluid,
            media: media.sm_mobile
        }
    ]

    return <div
        className={classNames({
            [Style.item]: true,
            [Style.active]: active,
            [Style.inactive]: !active,
        })}
        onClick={onActivate}
    >
        <div hidden className={Style.background}>
            <Img className="h-full w-full z-0 pointer-events-none" fluid={images} alt="" />
        </div>
        <div className={Style.container}>
            <div className={Style.contentWrap}>
                <span className={Style.name}>{name}</span>
                <span className={Style.title}>{title}</span>
                <div className={classNames({
                    "hidden": !active,
                    [Style.description]: active
                })}>
                    {description}
                </div>
            </div>
            {!active && <div hidden aria-hidden={true} className={Style.buttonWrap}>
                <Button
                    className="inline-block"
                    type="button"
                    appearance="secondary"
                    equalPadding
                >
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.869141" y="5.7085" width="12" height="2" fill="#031096"/>
                        <rect x="7.86914" y="0.708496" width="12" height="2" transform="rotate(90 7.86914 0.708496)" fill="#031096"/>
                    </svg>
                </Button>
            </div>}
        </div>
    </div>
})

export const query = graphql`
    fragment CarouselItemImage on File {
        childImageSharp {
            fluid(maxWidth: 656, maxHeight: 632, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    } 
    fragment CarouselItemImageMobile on File {
        childImageSharp {
            fluid(maxWidth: 364, maxHeight: 364, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp,
            }
        }
    }
`
